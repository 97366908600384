import React from "react"
import { Link } from "gatsby"

import SVG from "react-inlinesvg"
import styles from "./footer.module.scss"
const logoScript = require("../../assets/images/logo-script.svg")

const Footer = () => (
  <>
    <footer className={styles.container} id="contact">
      <div className={styles.logo}>
        <SVG src={logoScript} className={styles.logoImage} />
        <span className={styles.logoTagline}>
          Brands.
          <br /> Built on
          <br /> behaviour.
        </span>
      </div>

      <address className={styles.address}>
        <div className={styles.addressColumn}>
          <div className={styles.addressName}>Melbourne</div>
          <a
            className={styles.addressLink}
            href="https://goo.gl/maps/k3kewp2FQ2pasMXn6"
            target="_blank"
          >
            <span className={styles.addressLine}>1/111 High St</span>
            <br />
            <span className={styles.addressLine}>Prahran VIC 3181</span>
          </a>
          <br />
          <a href="tel:+61385541444" className={styles.addressLine}>
            (03) 8554 1444
          </a>
        </div>
        <div className={styles.addressColumn}>
          <div className={styles.addressName}>Sydney</div>
          <a
            className={styles.addressLink}
            href="https://www.google.com/maps/place/100+Harris+St,+Pyrmont+NSW+2009/@-33.8684401,151.1937403,17z/data=!4m2!3m1!1s0x6b12ae3696be7fbf:0x7784d9732d4ec158"
            target="_blank"
          >
            <span className={styles.addressLine}>100 Harris St,</span>
            <br />
            <span className={styles.addressLine}>Sydney NSW 2009</span>
          </a>
          <br />
          <a href="tel:+61295380847" className={styles.addressLine}>
            (02) 9538 0847
          </a>
        </div>
      </address>

      <div className={styles.links}>
        <p
          className={`body3 ${styles.welcomeToCountry__desktop}`}
          style={{ color: "#656565" }}
        >
          In Naarm (Melbourne), we acknowledge that our office is located on
          Traditional Lands of the Wurundjeri people of the Kulin Nations. In
          Cadi (Sydney), we acknowledge that our office is located on
          Traditional Lands of the Gadigal people of the Eora nation. We pay our
          respect to their Elders past, present and emerging. We extend that
          respect to all Aboriginal and Torres Strait Islander peoples.
        </p>
        <Link to={`/contact/`} className={styles.careersLink}>
          Let's chat
        </Link>
        <div className={styles.connect}>
          <span className={styles.connectTitle}>Connect</span>
          <a
            target="_blank"
            href="https://www.facebook.com/hardhatagency/"
            className={styles.connectLink}
          >
            Fb
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/hardhatagency/"
            className={styles.connectLink}
          >
            Li
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/hardhatagency/"
            className={styles.connectLink}
          >
            In
          </a>
        </div>
      </div>
      <p
        className={`body3 ${styles.welcomeToCountry__device}`}
        style={{ color: "#656565" }}
      >
        In Naarm (Melbourne), we acknowledge that our office is located on
        Traditional Lands of the Wurundjeri people of the Kulin Nations. In Cadi
        (Sydney), we acknowledge that our office is located on Traditional Lands
        of the Gadigal people of the Eora nation. We pay our respect to their
        Elders past, present and emerging. We extend that respect to all
        Aboriginal and Torres Strait Islander peoples.
      </p>
    </footer>
  </>
)

export default Footer
