import React from "react"
import { Link } from "gatsby"

import SVG from "react-inlinesvg"
import scrollToElement from "scroll-to-element"
import * as styles from "./top_navigation.module.scss"

const logoCircle = require("../../assets/images/logo.svg")
const logoScript = require("../../assets/images/logo-script.svg")
const messageIcon = require("../../assets/images/message-icon.svg")

interface Props {
  theme: "light" | "dark"
  hoverColour?: string
  isHome?: boolean
  isContactUs?: boolean
  hideChat?: boolean
}

export default class TopNavigation extends React.Component<Props> {
  circleLogo: any
  scriptLogo: any
  constructor(props) {
    super(props)
    this.scrollTo = this.scrollTo.bind(this)
    this.scriptLogo = React.createRef()
    this.circleLogo = React.createRef()
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (
        this.props.isHome &&
        this.circleLogo.current &&
        this.scriptLogo.current
      ) {
        if (window.pageYOffset > 120) {
          this.circleLogo.current.style.opacity = 1
          this.scriptLogo.current.style.opacity = 0
        } else {
          this.circleLogo.current.style.opacity = 0
          this.scriptLogo.current.style.opacity = 1
        }
      }
    })
  }

  scrollTo(id) {
    {
      if (typeof document !== "undefined") {
        const ele = document.getElementById(id)
        return (e) => {
          e.preventDefault()
          scrollToElement(ele, {
            ease: "out-quad",
            duration: 2000,
            offset: window.innerWidth <= 768 ? -120 : 0,
          })
        }
      }
    }
  }

  renderHomeLogo() {
    return (
      <>
        <span ref={this.scriptLogo} className={styles.logoScript}>
          <SVG src={logoScript} />
        </span>
        <span ref={this.circleLogo} className={styles.logoCircle}>
          <SVG src={logoCircle} />
        </span>
      </>
    )
  }

  renderInnerLogo() {
    return (
      <SVG
        src={logoCircle}
        className={
          this.props.isContactUs ? styles.contactLogoCircle : styles.logoCircle
        }
      />
    )
  }

  letsChat() {
    if (this.props.hideChat) {
      return null
    } else {
      return (
        <Link
          to={`/contact/`}
          className={`${styles.logoText} ${styles.link} ${styles.linkAlt}`}
        >
          <span
            className={`label1 ${styles.link} ${styles.linkText} hover-target hover-target-text-link`}
            data-hover-background="rgba(0,0,0,0.5)"
          >
            Let's&nbsp;chat
          </span>
          <SVG src={messageIcon} className={styles.chat} />
        </Link>
      )
    }
  }

  navLinks() {
    return (
      <nav>
        <a
          href="#"
          onClick={this.scrollTo("work")}
          className={`${styles.link} url hover-target hover-target-text-link`}
        >
          Work
        </a>
        <a
          href="#"
          onClick={this.scrollTo("team")}
          className={`${styles.link} url hover-target hover-target-text-link`}
        >
          Team
        </a>
        <Link
          to={`/contact/`}
          className={`${styles.link} url hover-target hover-target-text-link`}
        >
          Contact
        </Link>
      </nav>
    )
  }

  render() {
    const props = this.props
    return (
      <div
        className={`${styles.container} ${styles[props.theme]} ${
          this.props.isHome ? "" : styles.containerInner
        }`}
      >
        <div className={styles.left}>
          <Link to={`/`} className={`label1 ${styles.logoText} ${styles.link}`}>
            {this.props.isHome ? this.renderHomeLogo() : this.renderInnerLogo()}
            {!props.isHome && (
              <span
                className={`hover-target hover-target-text-link ${styles.linkText} ${styles.logoLinkText}`}
                data-hover-background="rgba(0,0,0,0.5)"
              >
                &lsaquo;&nbsp;Back&nbsp;to&nbsp;home
              </span>
            )}
          </Link>
        </div>
        <div className={styles.right}>
          {props.isHome ? this.navLinks() : this.letsChat()}
        </div>
      </div>
    )
  }
}
